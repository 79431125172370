<template>
	<div class="JsonAutotestSettingsField" :class="{ error }" v-if="value">
		<div class="row">
			<div><label>Priority</label><select v-model="value.priority" @input="validate" style="width: 50px;"><option value="1">1</option><option value="2">2</option><option value="3">3</option></select></div>
			<div><label>Schedule</label><select v-model="value.schedule" @input="validate" style="width: 80px;"><option value="daily">daily</option><option value="hourly">hourly</option><option value="15min">15min</option></select></div>
			<div><label>SLA Class</label><select v-model="value.slaClass" @input="validate" style="width: 80px;"><option value="high">high</option><option value="normal">normal</option><option value="low">low</option><option value="no">no</option></select></div>
			<div><label>Subsystem</label><input type="text" v-model="value.subsystem" @input="validate" /></div>
			<div><label>Active From</label><input type="date" v-model="value.activeFrom" @input="validate" style="width: 80px;" /></div>
			<div><label>Active Until</label><input type="date" v-model="value.activeUntil" @input="validate" style="width: 80px;" /></div>
		</div>
		<div class="row">
			<a href="#">Metis SKISUP-123</a>
			<a href="#">Item</a>
			<div class="spacer"></div>
			<button>Run Now</button>
		</div>
		<div class="timeline" style="background: green; color: white; padding: 5px; border-radius: 5px;">
			TODO: timeline
		</div>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

export default {
	name: 'JsonAutotestSettingsField',
	mixins: [ field ],
	props: {
		value: [ Object ],
	},
	data: () => ({
		autoModel: false,
	}),
	watch: {
		value: {
			handler() {
				this.$emit('change', this.value)
			},
			deep: true,
		},
	},
	methods: {
		validate() {
			this.onErrors([
				this.validateRequired(),
			])
		},
	},
	mounted() {
		if (!this.value) this.$emit('input', { priority: 1, schedule: 'hourly', slaClass: 'low', subsystem: '', activeFrom: '', activeUntil: '' })
	},
}
</script>

<style scoped>
.JsonAutotestSettingsField { background: var(--color-element-light); border: 1px solid var(--color-element-mid); border-radius: 5px; padding: 10px; display: flex; flex-direction: column; gap: 10px; }
.row { display: flex; gap: 10px; align-items: center; }
.row > div { display: flex; flex-direction: column; gap: 5px; }
label { color: var(--color-text-lightest); }
select,
input { font-size: inherit; padding: 5px; border: 1px solid var(--color-element-mid); border-radius: 5px; }
button { padding: 7px 10px; border-radius: 5px; background: var(--color-element-dark); color: white; border: 0; }
.spacer { flex: 1; }
</style>
