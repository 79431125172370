<template>
	<Dialog ref="dialog">
		<h1 v-if="field">
			{{ field.name }}
			<span style="font-size: 14px; color: gray; margin-left: 3px; font-weight: normal">
				{{ field.type }}
				<span v-if="field.type == 'Array'">({{ field.items.type }})</span>
			</span>
		</h1>
		<div v-if="field && control?.settings" style="padding: 15px 25px; font-size: 14px; font-weight: normal; overflow: auto; height: calc(100% - 170px);">
			<div class="fields">
				<h2>Name and Field ID</h2>
				<div class="row">
					<div>
						<label for="name">Name (required)</label>
						<input id="name" class="field" type="text" v-model="field.name" />
					</div>
					<div>
						<label for="id">Field ID</label>
						<input id="id" class="field" type="text" v-model="field.id" disabled />
					</div>
				</div>
				<div class="block">
					<label for="description">Description</label>
					<input id="description" class="field wide" type="text" v-model="field.description" />
				</div>
			</div>

			<div class="fields">
				<h2>Settings</h2>
				<h3>Field options</h3>
				<div v-if="field.type == 'Symbol'" class="checkboxBlock">
<!-- TODO: this actually needs to map to the type.displayField! -->
					<input type="checkbox" v-model="isTitle" id="isTitle" />
					<label for="isTitle">This field represents the Entry title</label>
				</div>
				<div class="checkboxBlock">
					<input type="checkbox" v-model="field.localized" id="localized" />
					<label for="localized">Enable localization of this field</label>
					<p>All the content can be translated to German, English and 3 other locales</p>
				</div>
				<div class="checkboxBlock">
					<input type="checkbox" v-model="field.disabled" id="disabled" />
					<label for="disabled">Disable field</label>
					<p>When you disable the field, it will not show in the editor (unless you turn on 'show disabled fields' there)</p>
				</div>

				<h2>Validation</h2>
				<div class="checkboxBlock">
					<input type="checkbox" v-model="field.required" id="required" />
					<label for="required">Required field</label>
					<p>You won't be able to publish an entry if this field is empty</p>
				</div>

				<FieldSettingValidation validation="unique" title="Unique field" :hasMessage="false" :validations="validations" :typeHasValidation="typeHasValidation">
					<template #help>You won't be able to publish an entry if there is an existing entry with identical content</template>
				</FieldSettingValidation>

				<FieldSettingValidation validation="size" title="Limit character count" :validations="validations" :typeHasValidation="typeHasValidation">
					<template #help>Specify a minimum and/or maximum allowed number of characters</template>
					<FieldSettingSize :validation="validations.size" />
				</FieldSettingValidation>

				<FieldSettingValidation validation="array_size" title="Accept only a specified number of items" :validations="validations" :typeHasValidation="typeHasValidation">
					<template #help>Specify a minimum and/or maximum allowed number of items</template>
					<FieldSettingSize :validation="validations.array_size" />
				</FieldSettingValidation>

				<FieldSettingValidation validation="range" title="Accept only specified number range" :validations="validations" :typeHasValidation="typeHasValidation">
					<template #help>Specify a minimum and/or maximum allowed number for this field</template>
					<FieldSettingSize :validation="validations.range" />
				</FieldSettingValidation>

				<FieldSettingValidation validation="dateRange" title="Accept only specified date range" :validations="validations" :typeHasValidation="typeHasValidation">
					<template #help>Specify an early and/or latest allowed date for this field</template>
					<div v-if="validations.dateRange.enabled" class="row">
						<DateField v-model="validations.dateRange.dateRange.min" />
					</div>
					<div v-if="validations.dateRange.enabled" class="row">
						<DateField v-model="validations.dateRange.dateRange.max" />
					</div>
				</FieldSettingValidation>

				<FieldSettingValidation validation="regexp" title="Match a specific regexp" :validations="validations" :typeHasValidation="typeHasValidation">
					<template #help>Make this field match a regexp: e-mail address, URI, or a custom regular expression</template>
					<div v-if="validations.regexp.enabled" class="row">
						<select class="field" v-model="validations.regexp.regexp.pattern">
							<option value="">Custom</option>
							<option value="^\w[\w.-]*@([\w-]+\.)+[\w-]+$">E-Mail</option>
							<option value="^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?$">URL</option>
							<option value="^(0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?\d\d$">Date (US)</option>
							<option value="^(0?[1-9]|[12][0-9]|3[01])[- /.](0?[1-9]|1[012])[- /.](19|20)?\d\d$">Date (European)</option>
							<option value="^(0?[1-9]|1[012]):[0-5][0-9](:[0-5][0-9])?\s*[aApP][mM]$">12h Time</option>
							<option value="^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$">24h Time</option>
							<option value="^\d[ -.]?\(?\d\d\d\)?[ -.]?\d\d\d[ -.]?\d\d\d\d$">US Phone Number</option>
							<option value="^\d{5}$|^\d{5}-\d{4}$}">US Zip Code</option>
						</select>
						<input class="field" type="text" v-model="validations.regexp.regexp.pattern" />
					</div>
					<div v-if="validations.regexp.enabled">
						<label for="regexpFlags">Flags</label>
						<input id="regexpFlags" class="field" type="text" v-model="validations.regexp.regexp.flags" />
					</div>
				</FieldSettingValidation>

				<FieldSettingValidation validation="prohibitRegexp" title="Prohibit a specific regexp" :validations="validations" :typeHasValidation="typeHasValidation">
					<template #help>Make this field invalid when a regexp is matched: custom regular expression (e.g. bad word list).</template>
					<div v-if="validations.prohibitRegexp.enabled" class="row">
						<input class="field" type="text" v-model="validations.prohibitRegexp.prohibitRegexp.pattern" />
					</div>
					<div v-if="validations.prohibitRegexp.enabled">
						<label for="prohibitRegexpFlags">Flags</label>
						<input id="prohibitRegexpFlags" class="field" type="text" v-model="validations.prohibitRegexp.prohibitRegexp.flags" />
					</div>
				</FieldSettingValidation>

<!-- TODO: for integers we must only allow integers in the IN -->
				<FieldSettingValidation validation="in" title="Accept only specified values" :validations="validations" :typeHasValidation="typeHasValidation">
					<template #help>You won't be able to publish an entry if the field value is not in the list of specified values.</template>
					<TagsField v-if="validations.in.enabled" v-model="validations.in.in" />
				</FieldSettingValidation>

				<FieldSettingValidation validation="enabledMarks" title="Formatting" :hasMessage="false" :validations="validations" :typeHasValidation="typeHasValidation">
					<template #help>Allow certain text formatting like bold or italic.</template>
					<div v-if="validations.enabledMarks.enabled">
						<TogglesField v-model="validations.enabledMarks.enabledMarks" :def="{ items: { validations: [ { in: [ 'bold', 'italic', 'underline', 'code', 'superscript', 'subscript' ] } ] } }">
							<template #icon="{ value }">
								<mdi v-if="value == 'bold'" format-bold />
								<mdi v-if="value == 'italic'" format-italic />
								<mdi v-if="value == 'underline'" format-underline />
								<mdi v-if="value == 'code'" code-tags />
								<mdi v-if="value == 'superscript'" format-superscript />
								<mdi v-if="value == 'subscript'" format-subscript />
							</template>
						</TogglesField>
					</div>
				</FieldSettingValidation>

				<FieldSettingValidation validation="enabledNodeTypes" title="Structuring" :hasMessage="false" :validations="validations" :typeHasValidation="typeHasValidation">
					<template #help>Allow certain structuring elements.</template>
					<div v-if="validations.enabledNodeTypes.enabled">
						<TogglesField v-model="validations.enabledNodeTypes.enabledNodeTypes"
							:def="{ items: { validations: [ { in: [
								'heading-1', 'heading-2', 'heading-3', 'heading-4', 'heading-5', 'heading-6',
								'ordered-list', 'unordered-list', 'hr', 'blockquote',
								'embedded-asset-block', 'asset-hyperlink',
								'embedded-entry-block', 'embedded-entry-inline', 'entry-hyperlink',
								'hyperlink', 'table',
							] } ] } }"
							:groups="{ 'heading-1': 'Headlines', 'ordered-list': 'Blocks', 'embedded-asset-block': 'Assets', 'embedded-entry-block': 'Entries', 'hyperlink': 'Other' }"
						>
							<template #icon="{ value }">
								<mdi v-if="value == 'heading-1'" format-header-1 />
								<mdi v-if="value == 'heading-2'" format-header-2 />
								<mdi v-if="value == 'heading-3'" format-header-3 />
								<mdi v-if="value == 'heading-4'" format-header-4 />
								<mdi v-if="value == 'heading-5'" format-header-5 />
								<mdi v-if="value == 'heading-6'" format-header-6 />
								<mdi v-if="value == 'ordered-list'" format-list-numbered />
								<mdi v-if="value == 'unordered-list'" format-list-bulleted />
								<mdi v-if="value == 'hr'" minus-thick />
								<mdi v-if="value == 'blockquote'" format-quote-open />
								<mdi v-if="value == 'embedded-entry-block'" file-document />
								<mdi v-if="value == 'embedded-asset-block'" file-image />
								<mdi v-if="value == 'table'" table-large />
								<mdi v-if="value == 'hyperlink'" link-variant />
								<mdi v-if="value == 'entry-hyperlink'" file-document-outline />
								<mdi v-if="value == 'asset-hyperlink'" file-image-outline />
								<mdi v-if="value == 'embedded-entry-inline'" text-box />
							</template>
						</TogglesField>
					</div>
				</FieldSettingValidation>

				<FieldSettingValidation validation="linkContentType" title="Accept only specified entry type" :validations="validations" :typeHasValidation="typeHasValidation">
					<template #help>Make this field only accept entries from specified content type(s)</template>
					<MultiTypePicker v-if="validations.linkContentType.enabled" style="margin-top: -30px;" v-model="validations.linkContentType.linkContentType" />
				</FieldSettingValidation>

				<FieldSettingValidation headline="Assets" validation="size" title="Limit number of embedded assets" :validations="validations.nodes['embedded-asset-block']" :typeHasValidation="typeHasNodes"
					:disabled="!nodeTypeEnabled('embedded-asset-block')"
				>
					<template #help>Specify a minimum and/or maximum allowed number of assets</template>
					<FieldSettingSize :validation="validations.nodes['embedded-asset-block'].size" />
				</FieldSettingValidation>

				<FieldSettingValidation validation="size" title="Limit number of linked assets" :validations="validations.nodes['asset-hyperlink']" :typeHasValidation="typeHasNodes"
					:disabled="!nodeTypeEnabled('asset-hyperlink')"
				>
					<template #help>Specify a minimum and/or maximum allowed number of assets</template>
					<FieldSettingSize :validation="validations.nodes['asset-hyperlink'].size" />
				</FieldSettingValidation>

				<FieldSettingValidation headline="Entries" validation="size" title="Limit number of block entries" :validations="validations.nodes['embedded-entry-block']" :typeHasValidation="typeHasNodes"
					:disabled="!nodeTypeEnabled('embedded-entry-block')"
				>
					<template #help>Specify a minimum and/or maximum allowed number of entries</template>
					<FieldSettingSize :validation="validations.nodes['embedded-entry-block'].size" />
				</FieldSettingValidation>
				<FieldSettingValidation validation="linkContentType" title="Accept only specified entry type for block entries" :validations="validations.nodes['embedded-entry-block']" :typeHasValidation="typeHasNodes"
					:disabled="!nodeTypeEnabled('embedded-entry-block')"
				>
					<template #help>Make this link type only accept entries from specified content type(s)</template>
					<MultiTypePicker v-if="validations.nodes['embedded-entry-block'].linkContentType.enabled" style="margin-top: -30px;" v-model="validations.nodes['embedded-entry-block'].linkContentType.linkContentType" />
				</FieldSettingValidation>

				<FieldSettingValidation validation="size" title="Limit number of inline entries" :validations="validations.nodes['embedded-entry-inline']" :typeHasValidation="typeHasNodes"
					:disabled="!nodeTypeEnabled('embedded-entry-inline')"
				>
					<template #help>Specify a minimum and/or maximum allowed number of entries</template>
					<FieldSettingSize :validation="validations.nodes['embedded-entry-inline'].size" />
				</FieldSettingValidation>
				<FieldSettingValidation validation="linkContentType" title="Accept only specified entry type for inline entries" :validations="validations.nodes['embedded-entry-inline']" :typeHasValidation="typeHasNodes"
					:disabled="!nodeTypeEnabled('embedded-entry-inline')"
				>
					<template #help>Make this link type only accept entries from specified content type(s)</template>
					<MultiTypePicker v-if="validations.nodes['embedded-entry-inline'].linkContentType.enabled" style="margin-top: -30px;" v-model="validations.nodes['embedded-entry-inline'].linkContentType.linkContentType" />
				</FieldSettingValidation>

				<FieldSettingValidation validation="size" title="Limit number of linked entries" :validations="validations.nodes['entry-hyperlink']" :typeHasValidation="typeHasNodes"
					:disabled="!nodeTypeEnabled('entry-hyperlink')"
				>
					<template #help>Specify a minimum and/or maximum allowed number of entries</template>
					<FieldSettingSize :validation="validations.nodes['entry-hyperlink'].size" />
				</FieldSettingValidation>
				<FieldSettingValidation validation="linkContentType" title="Accept only specified entry type for linked entries" :validations="validations.nodes['entry-hyperlink']" :typeHasValidation="typeHasNodes"
					:disabled="!nodeTypeEnabled('entry-hyperlink')"
				>
					<template #help>Make this link type only accept entries from specified content type(s)</template>
					<MultiTypePicker v-if="validations.nodes['entry-hyperlink'].linkContentType.enabled" style="margin-top: -30px;" v-model="validations.nodes['entry-hyperlink'].linkContentType.linkContentType" />
				</FieldSettingValidation>

				<h2>Default value</h2>
<!-- TODO: i18n for default values -->
<!-- TODO: use the right widget for the default value - use Field with the fieldDef! -->
				<div v-if="field.type == 'Symbol' || field.type == 'Boolean' || field.type == 'Text' || field.type == 'Number' || field.type == 'Integer' || field.type == 'Date' || field.type == 'Array' && field.items.type == 'Symbol'">
					<Info style="margin-bottom: 20px;">
						This setting allows you to set a default value for this field, which will be automatically inserted to new content entries. It can help editors avoid content entry altogether, or just give them a helpful prompt for how to structure their content.
					</Info>
					<div class="block" v-if="field.defaultValue">
						<div v-for="locale of field.localized ? locales : [ { name: '', code: defaultLocale } ]" :key="'f-' + locale.code">
							<Field
								:field="fieldOutForDefault"
								:control="control"
								v-model="field.defaultValue[locale.code]"
								:locale="locale.code"
								:entry="entry"
								:titleSuffix="locale.name"
							/>
						</div>
					</div>
					<div v-else>
						<Info>
							ERROR: could not find defaultValue on field!
						</Info>
					</div>
				</div>
				<div v-else>
					<Info>
						<label for="defaultValue">Not available for this field type</label>
						<p>You can only set a default value for the text, boolean, date and time, number and text list field types.</p>
					</Info>
				</div>

<!-- TODO: all of appearance will actually have to go into the ui infos - should we extend the field schema? -->
				<h2>Appearance</h2>
<!-- TODO: widgetNameSpace = builtin | ?? -->
<!-- TODO: what other settings on control exist? -->
<!-- TODO: default for symbols should be singleLine - how? -->
				<!--DEBUG widgetId={{ control.widgetId }}-->
				<div v-if="field.type == 'Symbol'">
					<input type="radio" v-model="control.widgetId" name="widgetId" value="singleLine" id="singleLine" />
					<label for="singleLine">Single Line</label>
					<input type="radio" v-model="control.widgetId" name="widgetId" value="url" id="url" />
					<label for="url">URL</label>
					<input type="radio" v-model="control.widgetId" name="widgetId" value="dropdown" id="dropdown" />
					<label for="dropdown">Dropdown</label>
					<input type="radio" v-model="control.widgetId" name="widgetId" value="radio" id="radio" />
					<label for="radio">Radio</label>
					<input type="radio" v-model="control.widgetId" name="widgetId" value="slug" id="slug" />
					<label for="slug">Slug</label>
					<input type="radio" v-model="control.widgetId" name="widgetId" value="qrGenerator" id="qrGenerator" />
					<label for="qrGenerator">QR Code Generator</label>
				</div>
				<div v-if="field.type == 'Array' && field.items.type == 'Symbol'">
					<input type="radio" v-model="control.widgetId" name="widgetId" value="tagEditor" id="tagEditor" />
					<label for="tagEditor">Tags</label>
					<input type="radio" v-model="control.widgetId" name="widgetId" value="checkbox" id="checkbox" />
					<label for="checkbox">Checkboxes</label>
					<input type="radio" v-model="control.widgetId" name="widgetId" value="listInput" id="listInput" />
					<label for="listInput">List</label>
				</div>
<!-- TODO: more control ids for other types -->
				<div v-if="field.type == 'Integer'">
					<input type="radio" v-model="control.widgetId" name="widgetId" value="numberEditor" id="numberEditor" />
					<label for="numberEditor">Number Editor</label>
					<input type="radio" v-model="control.widgetId" name="widgetId" value="dropdown" id="dropdown" />
					<label for="dropdown">Dropdown</label>
					<input type="radio" v-model="control.widgetId" name="widgetId" value="radio" id="radio" />
					<label for="radio">Radio</label>
					<input type="radio" v-model="control.widgetId" name="widgetId" value="rating" id="rating" />
					<label for="rating">Rating</label>
<!-- TODO: remove this value from settings if widgetId is different -->
					<select v-if="control.widgetId == 'rating'" v-model="control.settings.stars">
						<option value="1">1 Stars</option>
						<option value="2">2 Stars</option>
						<option value="3">3 Stars</option>
						<option value="4">4 Stars</option>
						<option value="5">5 Stars</option>
						<option value="6">6 Stars</option>
						<option value="7">7 Stars</option>
						<option value="8">8 Stars</option>
						<option value="9">9 Stars</option>
						<option value="10">10 Stars</option>
					</select>
				</div>
				<div v-if="field.type == 'Object'">
					<input type="radio" v-model="control.widgetId" name="widgetId" value="objectEditor" id="objectEditor" />
					<label for="objectEditor">Textarea</label>
					<input type="radio" v-model="control.widgetId" name="widgetId" value="propertiesEditor" id="propertiesEditor" />
					<label for="propertiesEditor">Properties</label>
					<input type="radio" v-model="control.widgetId" name="widgetId" value="jsonTable" id="jsonTable" />
					<label for="jsonTable">Table</label>
					<input type="radio" v-model="control.widgetId" name="widgetId" value="jsonWgs84Polyline" id="jsonWgs84Polyline" />
					<label for="jsonWgs84Polyline">WGS84 Polyline</label>
					<input type="radio" v-model="control.widgetId" name="widgetId" value="autotestSettings" id="autotestSettings" />
					<label for="autotestSettings">Autotest Settings</label>
				</div>
				<div class="block" v-if="field.type == 'Boolean'">
					<label for="trueLabel">True condition custom label</label>
					<input class="field wide" id="trueLabel" type="text" v-model="control.settings.trueLabel" />
					<label for="falseLabel">False condition custom label</label>
					<input class="field wide" id="falseLabel" type="text" v-model="control.settings.falseLabel" />
				</div>
				<div class="block">
					<label for="helpText">Help text</label>
					<input id="helpText" class="field wide" type="text" v-model="control.settings.helpText" />
				</div>
				<div class="block">
					<label for="group">Field Group</label>
					<input id="group" class="field wide" type="text" v-model="control.settings.group" />
				</div>
			</div>
			<pre class="code">{{ jsonField }}</pre>
			<!--<pre class="code">{{ jsonControl }}</pre>-->
		</div>
		<div class="buttons">
			<ActionButton class="delete" @click="del">Delete</ActionButton>
			<ActionButton class="cancel" @click="close">Cancel</ActionButton>
			<ActionButton class="neutral" @click="confirm" :disabled="!valid">Confirm</ActionButton>
		</div>
	</Dialog>
</template>

<script>
import Info from '../../views/Info.vue'
import Dialog from '../Dialog.vue'
import MultiTypePicker from '../MultiTypePicker.vue'
import DateField from './DateField.vue'
import Field from './Field.vue'
import FieldSettingSize from './FieldSettingSize.vue'
import FieldSettingValidation from './FieldSettingValidation.vue'
import TagsField from './TagsField.vue'
import TogglesField from './TogglesField.vue'
import ActionButton from '../ActionButton.vue'

// TODO: ALSO: BOOL: Show "Create new assets"
//       When enabled, people can create and link new assets (based on user permissions)

// TODO: ALSO: BOOL: Show "Link existing assets"
//       When enabled, people can link existing assets (based on user permissions)

export default {
	name: 'FieldSettingsDialog',
	components: { Dialog, DateField, Info, TagsField, TogglesField, MultiTypePicker, FieldSettingValidation, FieldSettingSize, Field, ActionButton },
	inject: [ 'defaultLocale', 'locales' ],
	props: {},
	data: () => ({
		field: {},
		control: {},
		type: {},
		isTitle: false,
		validationsNew: null,
		validations: {
			// String
			unique: { enabled: false, unique: true },
			size: { enabled: false, size: { min: '', max: '' }, message: '' },
			regexp: { enabled: false, regexp: { pattern: '', flags: '' }, message: '' },
			prohibitRegexp: { enabled: false, prohibitRegexp: { pattern: '', flags: '' }, message: '' },
			in: { enabled: false, in: [], message: '' },
			// Number
			range: { enabled: false, range: { min: '', max: '' }, message: '' },
			// Rich Text
			enabledMarks: { enabled: false, enabledMarks: [ 'bold', 'italic', 'underline', 'code', 'superscript', 'subscript' ], message: '' },
			enabledNodeTypes: { enabled: false, enabledNodeTypes: [
				'heading-1', 'heading-2', 'heading-3', 'heading-4', 'heading-5', 'heading-6',
				'ordered-list', 'unordered-list', 'hr', 'blockquote',
				'embedded-entry-block', 'embedded-entry-inline', /*'entry-hyperlink',*/
				'embedded-asset-block', /*'asset-hyperlink',*/
				'hyperlink', /*'table',*/
			], message: '' },
			// NOTE: these Rich Text validations are grouped in a "nodes" sub structure
			nodes: {
				'embedded-entry-block': {
					size: { enabled: false, size: { min: '', max: '' }, message: '' },
					linkContentType: { enabled: false, linkContentType: [], message: '' },
				},
				'embedded-entry-inline': {
					size: { enabled: false, size: { min: '', max: '' }, message: '' },
					linkContentType: { enabled: false, linkContentType: [], message: '' },
				},
				'entry-hyperlink': {
					size: { enabled: false, size: { min: '', max: '' }, message: '' },
					linkContentType: { enabled: false, linkContentType: [], message: '' },
				},
				'embedded-asset-block': {
					size: { enabled: false, size: { min: '', max: '' }, message: '' },
				},
				'asset-hyperlink': {
					size: { enabled: false, size: { min: '', max: '' }, message: '' },
				},
			},
			// Date
			dateRange: { enabled: false, dateRange: { min: '', max: '' }, message: '' },
			// Link (Entry)
			linkContentType: { enabled: false, linkContentType: [], message: '' },
			// Array
			array_size: { enabled: false, size: { min: '', max: '' }, message: '' },
// TODO: for arrays - are these on "items"?
			// Asset
			linkMimetypeGroup: { enabled: false, linkMimetypeGroup: [], message: '' },
			//linkContentType: { enabled: false, linkContentType: [], message: '' },
			assetFileSize: { enabled: false, assetFileSize: { min: '', max: '' }, message: '' },
			assetImageDimensions: { enabled: false, assetImageDimensions: { width: { min: '', max: '' }, height: { min: '', max: '' } }, message: '' },
		},
		validationsReusableForItems: [ 'in', 'size', 'linkContentType', 'linkMimetypeGroup', 'assetFileSize', 'assetImageDimensions' ],
		typeValidations: {
			Symbol: [ 'unique', 'size', 'regexp', 'prohibitRegexp', 'in' ],
			Integer: [ 'unique', 'range', 'in' ],
			Number: [ 'unique', 'range', 'in' ],
			RichText: [ 'size', 'enabledMarks', 'enabledNodeTypes', 'nodes' ],
			Text: [ 'size', 'regexp', 'prohibitRegexp', 'in' ],
			Date: [ 'dateRange' ],
			Array: { // field.items.type
				// TODO: is the regex and prohibit regex meant to be on items or as a whole?
				// TODO: can size really be on both at the same time?
				//       yes, we have 'Accept only a specified number of symbols' and 'Limit character count'
				Symbol: [ 'size', 'regexp', 'prohibitRegexp', 'array_size', 'in' ],
				Link: { // field.linkType
					Entry: [ 'linkContentType' ], // TODO: more
					Asset: [ 'linkMimetypeGroup', 'assetFileSize', 'assetImageDimensions' ], // TODO: more
				},
			},
			Link: { // field.linkType
				Entry: [ 'linkContentType' ],
				Asset: [ 'linkMimetypeGroup', 'assetFileSize', 'assetImageDimensions' ],
			},
			Location: [],
			// TODO: special default value selector!
			Boolean: [],
			Object: [],
		},
	}),
	computed: {
		// fake entry for defaultValue Field
		entry() {
			return {
				fields: { [this.field.id]: this.field.defaultValue },
				sys: { id: 'FAKE', contentType: this.type },
			}
		},
		valid() {
			return true
		},
		fieldOut() {
			const r = {
				...this.field,
				validations: this.modelToValidations(this.validations),
			}
			if (this.field.type == 'Array') {
				r.items = {
					...this.field.items,
					validations: this.modelToValidations(this.validations, true),
				}
			}
			if (this.field.type == 'RichText') {
				const nv = {}
				for (const n in this.validations.nodes) {
					const vals = this.modelToValidations(this.validations.nodes[n])
					nv[n] = vals.length ? vals : undefined
				}
				r.validations.push({ nodes: nv })
			}

			// remove defaultValue if it contains no values!
			const dv = this.field.defaultValue
			let hasDefault = false
			for (const l in dv) {
				if (dv[l] === undefined || dv[l] === null || dv[l] === '') dv[l] = undefined
				else hasDefault = true
			}
			if (!hasDefault) r.defaultValue = undefined

			return r
		},
		// the default fields must not actually be required
		fieldOutForDefault() {
			const f = JSON.parse(JSON.stringify(this.fieldOut))
			f.required = false
			return f
		},
		jsonField() {
			return JSON.stringify(this.fieldOut, null, 2)
		},
		jsonControl() {
			return JSON.stringify(this.control, null, 2)
		},
	},
	methods: {
		open(field, control, type) {
			if (!this.validationsNew) this.validationsNew = JSON.parse(JSON.stringify(this.validations))
			this.validations = JSON.parse(JSON.stringify(this.validationsNew))

			field = JSON.parse(JSON.stringify(field))
			field.defaultValue = field.defaultValue ?? {}
			this.field = field
			this.control = control === undefined ? undefined : JSON.parse(JSON.stringify(control))
			this.type = type
			this.isTitle = type.displayField == field.id

			// map validations to internal model
			this.validationsToModel(field.validations, field.type, false)
			// for arrays we take some validations from items: { validations: { .. } }
			this.validationsToModel(field.items?.validations, field.type, true)

			this.$refs.dialog.open()
		},
		// take in unuseable CF model and map to internal model
		// like: [ { key: { .. } }, .. ] => { key: { .. }, .. }
		validationsToModel(validations, type, isItems = false) {
			if (!validations) return
			for (let validation of validations) {
				for (const v in validation) {
					if (v == 'message') continue

					// some item validations (size) can not be reused because both the array and the item can have them
					let lv = v
					// TODO: this could be more generic..
					if (type == 'Array' && !isItems && v == 'size') lv = 'array_' + lv
					const localValidation = this.validations[lv]

					// ignore unsupported validations
					if (!localValidation) continue

					if (v == 'nodes') {
						// nodesInput = { "embedded-entry-block": [ { "linkContentType": [ "test" ] } ], .. }
						const nodesInput = validation[v]
						const nodes = this.validations.nodes
						// n = 'embedded-entry-block', 'embedded-entry-inline', ..
						for (const n in nodesInput) {
							// nodeInput = [ { "linkContentType": [ "test" ] } ]
							const nodeInput = nodesInput[n]
							// node = { 'embedded-entry-block': { linkContentType: { enabled: false, linkContentType: [], message: '' }, .. }}
							const node = nodes[n]
							if (!node) continue
							for (validation of nodeInput) {
								// v = 'linkContentType', ..
								for (let v in validation) {
									if (v == 'message') continue
									node[v].enabled = true
									node[v][v] = validation[v]
									if (node[v].message !== undefined)
										node[v].message = validation.message ? validation.message : ''
								}
							}
						}
						continue
					}

					// ignore arrays of validations here
					if (localValidation.enabled === undefined) continue
					localValidation.enabled = true
					for (const k in validation[v]) {
						localValidation[v][k] = validation[v][k]
						if (validation[v][k] && typeof validation[v][k] == 'object') {
							for (const l in validation[v][k]) {
								localValidation[v][k][l] = validation[v][k][l]
							}
						}
					}
					if (localValidation.message !== undefined)
						localValidation.message = validation.message ? validation.message : ''
				}
			}
		},
		// transform validations into contentful format
		// like: { key: { .. }, .. } => [ { key: { .. } }, .. ]
		modelToValidations(validations, isItems = false) {
			const r = []
			for (const v in validations) {
				if (!validations[v].enabled) continue
				const o = JSON.parse(JSON.stringify(validations[v]))

				// remove unneeded and empty props
				delete o.enabled
				for (const k in o) {
					if (o[k] == '') delete o[k]
					if (o[k] && typeof o[k] == 'object') {
						for (const l in o[k]) {
							if (o[k][l] == '') delete o[k][l]
						}
					}
				}

				// if we are operating on items, we only want to add item props
				const onlyArray = v.startsWith('array_')
				const reusable = this.validationsReusableForItems.includes(v)
				if (this.field.type == 'Array') {
					// on items we can not use only-array validations
					if (isItems && onlyArray) continue
					// on items we can only use reusable validations
					if (isItems && !onlyArray && !reusable) continue
					// on array we can only use non-only-array validations
					if (!isItems && !onlyArray) continue
				}
				else {
					if (isItems) continue
				}
				r.push(o)
			}
			return r
		},
		typeHasValidation(validation) {
			const tv = this.typeValidations[this.field.type]
			if (this.field.type == 'Array') {
				const iv = tv?.[this.field.items.type]
				// TODO: only if reusable?
				if (this.field.items.type == 'Link') return iv?.[this.field.items.linkType]?.includes?.(validation)
				return iv?.includes?.(validation)
			}
			if (this.field.type == 'Link') return tv?.[this.field.linkType]?.includes?.(validation)
			return tv?.includes?.(validation)
		},
		typeHasNodes() {
			const tv = this.typeValidations[this.field.type]
			return tv?.includes?.('nodes')
		},
		close() {
			this.$refs.dialog.close()
		},
		del() {
			this.$emit('delete', this.field)
			this.close()
		},
		confirm() {
			if (this.isTitle) this.type.displayField = this.field.id
			this.$emit('confirm', { field: this.fieldOut, control: this.control })
			this.close()
		},
		nodeTypeEnabled(type) {
			return this.validations.enabledNodeTypes.enabled && this.validations.enabledNodeTypes.enabledNodeTypes?.includes?.(type)
		},
	},
}
</script>

<style scoped>
h2 { margin: 10px 0 0 0 !important; font-size: 16px; }
h3 { margin: 0 !important; font-size: 13px; }
.fields { display: flex; flex-direction: column; gap: 10px; }
input[disabled] { background: #f9f9f9; }
.row { display: flex; gap: 10px; align-items: start; }
.row > * { flex-grow: 1; display: flex; flex-direction: column; gap: 5px; }
.checkboxBlock { padding-left: 30px; position: relative; }
.checkboxBlock > input[type="checkbox"] { position: absolute; left: 0px; }
.checkboxBlock > label { font-weight: 500; }
.checkboxBlock p { color: gray; }
.block > label,
.checkboxBlock > div label { display: block !important; margin-bottom: 4px; margin-top: 8px; }
.field { display: block; background-color: white; color: rgb(65, 77, 99); font-size: 0.875rem; line-height: 1.25rem; border-radius: 6px; box-shadow: rgb(225 228 232 / 20%) 0px 2px 0px inset; outline: none; border: 1px solid rgb(207, 217, 224); cursor: pointer; padding: 10px 1.5rem 10px 0.75rem; }
.field:focus { border: 1px solid var(--primary); }
.field.message,
.field.wide { width: calc(100% - 40px); }
button.toggle { min-width: 25px; font-size: 16px; padding: 3px 4px; background: transparent; border: 1px solid gray; color: gray; border-radius: 4px; margin-right: 4px; }
button.toggle.active { border-color: var(--primary); color: var(--primary); }

button.confirm { background-color: var(--primary); border-color: var(--primary); color: white; }
button.delete { background-color: rgb(189, 0, 43); border-color: rgb(189, 0, 43); color: white; }
</style>