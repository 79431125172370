<template>
	<FieldWrapper v-if="field" :title="field.name + (titleSuffix ? ' – ' + titleSuffix : '')" :def="field" :control="control" ref="wrap" class="Field FieldWrapper" :data-cy="'field-' + field.id">
		<!-- TODO: do we need to support single line fields with "Text" type? -->
		<TextField v-if="field.type == 'Symbol' && (!control.widgetId || control.widgetId == 'singleLine')" 			:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />
		<LongTextField v-if="field.type == 'Symbol' && control.widgetId == 'multipleLine'"								:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />
		<DropdownField v-if="field.type == 'Symbol' && control.widgetId == 'dropdown'"									:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />
		<SlugField v-if="field.type == 'Symbol' && (control.widgetId == 'slugEditor' || control.widgetId == 'slug')"	:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)"
			:entry="entry" :locale="locale" 
		/>
		<RadioField v-if="field.type == 'Symbol' && control.widgetId == 'radio'"										:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />
		<UrlField v-if="field.type == 'Symbol' && control.widgetId == 'urlEditor'"										:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />
		<QrGeneratorField v-if="field.type == 'Symbol' && control.widgetId == 'qrGenerator'"							:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />

		<!-- TODO: this is a weird hack to check on the widgetId length - actually these 'id' widgets should be mapped to something at import -->
		<TagsField v-if="field.type == 'Array' && field.items.type == 'Symbol' && (!control.widgetId || control.widgetId.length > 20 || control.widgetId == 'tagEditor')"
																														:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />
		<CheckboxesField v-if="field.type == 'Array' && field.items.type == 'Symbol' && control.widgetId == 'checkbox'"	:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />
		<!-- TODO: the listInput in CF is actually a comma separated text input -->
		<TagsField v-if="field.type == 'Array' && field.items.type == 'Symbol' && control.widgetId == 'listInput'"		:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />

		<NumberField v-if="field.type == 'Number' && (!control.widgetId || control.widgetId == 'numberEditor')"			:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />
		<NumberField v-if="field.type == 'Integer' && (!control.widgetId || control.widgetId == 'numberEditor')"		:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />
		<RatingField v-if="field.type == 'Integer' && control.widgetId == 'rating'"										:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />
		<DropdownField v-if="field.type == 'Integer' && control.widgetId == 'dropdown'"									:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />
		<RadioField v-if="field.type == 'Integer' && control.widgetId == 'radio'"										:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />

		<BooleanField v-if="field.type == 'Boolean'"																	:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="value" @wrapper="$refs.wrap.onWrapper($event)" />

		<DateField v-if="field.type == 'Date'"																			:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />

		<LongTextField v-if="field.type == 'Text' && (!control.widgetId || control.widgetId == 'multipleLine')"			:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />
		<TextField v-if="field.type == 'Text' && control.widgetId == 'singleLine'"										:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />
		<!-- TODO: CF actually has an editor for markdown - do we need it? -->
		<LongTextField v-if="field.type == 'Text' && control.widgetId == 'markdown'"									:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />
		<RichTextField v-if="field.type == 'RichText'"																	:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)"
			:locale="locale"
			@change="$emit('change', $event)"
			@subedit="forwardSubedit"
			@subeditAsset="forwardSubeditAsset"
		/>

		<LocationField v-if="field.type == 'Location'"																	:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />

		<JsonField v-if="field.type == 'Object' && (!control.widgetId || control.widgetId == 'objectEditor')"			:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)" />
		<JsonTableField v-if="field.type == 'Object' && control.widgetId == 'jsonTable'"								:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)"
			@change="$emit('change', $event)"
		/>
		<JsonWgs84PolylineField v-if="field.type == 'Object' && control.widgetId == 'jsonWgs84Polyline'"				:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)"
			@change="$emit('change', $event)"
		/>
		<JsonAutotestSettings v-if="field.type == 'Object' && control.widgetId == 'autotestSettings'"					:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)"
			@change="$emit('change', $event)"
		/>

		<ReferencesField v-if="field.type == 'Array' && field.items.linkType == 'Entry'"								:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)"
			@subedit="forwardSubedit"
		/>
		<ReferencesField v-if="field.type == 'Link' && field.linkType == 'Entry'"										:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)"
			@subedit="forwardSubedit"
		/>

		<AssetsField v-if="field.type == 'Array' && field.items.linkType == 'Asset'"									:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)"
			@subeditAsset="forwardSubeditAsset"
		/>
		<AssetsField v-if="field.type == 'Link' && field.linkType == 'Asset'"											:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)"
			@subeditAsset="forwardSubeditAsset"
		/>

		<FileField v-if="field.type == 'File'"																			:title="field.name" :def="field" :control="control" :disabled="disabled" v-model="model" @wrapper="$refs.wrap.onWrapper($event)"
			:entry="entry"
		/>

		<!-- TODO: BulkEditor -->
	</FieldWrapper>
</template>

<script>
import FieldWrapper from './FieldWrapper.vue'
import TextField from './TextField.vue'
import RichTextField from './RichTextField.vue'
import ReferencesField from './ReferencesField.vue'
import CheckboxesField from './CheckboxesField.vue'
import TagsField from './TagsField.vue'
import BooleanField from './BooleanField.vue'
import DropdownField from './DropdownField.vue'
import SlugField from './SlugField.vue'
import UrlField from './UrlField.vue'
import LongTextField from './LongTextField.vue'
import NumberField from './NumberField.vue'
import RatingField from './RatingField.vue'
import RadioField from './RadioField.vue'
import DateField from './DateField.vue'
import LocationField from './LocationField.vue'
import JsonField from './JsonField.vue'
import AssetsField from './AssetsField.vue'
import FileField from './FileField.vue'
import QrGeneratorField from './QrGeneratorField.vue'
import JsonTableField from './JsonTableField.vue'
import JsonWgs84PolylineField from './JsonWgs84PolylineField.vue'
import JsonAutotestSettings from './JsonAutotestSettings.vue'

export default {
	name: 'Field',
	components: { TextField, FieldWrapper, RichTextField, ReferencesField, CheckboxesField, TagsField, BooleanField, DropdownField, SlugField, UrlField, LongTextField, NumberField, RatingField, RadioField, DateField, LocationField, JsonField, AssetsField, FileField, QrGeneratorField, JsonTableField, JsonWgs84PolylineField, JsonAutotestSettings },
	inject: [ 'locales' ],
	provide() {
		return {
			localeObject: this.localeObject,
		}
	},
	props: {
		control: Object,
		entry: Object,
		field: Object,
		value: [ String, Number, Object, Array, Boolean ],
		titleSuffix: String,
		locale: String,
		localeObject: Object,
		disabled: Boolean,
	},
	data: () => ({
		model: null,
	}),
	watch: {
		value(n) {
			this.model = n
		},
		model(n) {
			this.$emit('input', n)
		},
	},
	methods: {
		forwardSubedit(link, callback) {
			this.$emit('subedit', link, callback)
		},
		forwardSubeditAsset(link, callback) {
			this.$emit('subeditAsset', link, callback)
		},
		getErrors() {
			return this.$refs.wrap?.errors ?? []
		},
		getWarnings() {
			return this.$refs.wrap?.warnings ?? []
		},
	},
	mounted() {
		this.model = this.value
	},
}
</script>

<style scoped>
</style>
