import { render, staticRenderFns } from "./ContentCompare.vue?vue&type=template&id=34a8ebfb&scoped=true&"
import script from "./ContentCompare.vue?vue&type=script&lang=ts&"
export * from "./ContentCompare.vue?vue&type=script&lang=ts&"
import style0 from "./ContentCompare.vue?vue&type=style&index=0&id=34a8ebfb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34a8ebfb",
  null
  
)

export default component.exports